import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import MainBlog from './MainBlog'
import LatestBlog from './LatestBlog'
import FAQ from '../FAQ'
import RecipesBlog from './RecipesBlog'

const Wrap = styled.div`
    max-width: 748px;
    margin: 0 auto;
    @media screen and (max-width: 600px) {
        min-width:85%;
        margin: 0 32px;
    }
`
const BlogTitle = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 57px;
text-align: center;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;

`
const BlogP = styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #424242;
mix-blend-mode: normal;
margin-top: -1rem;
`
const GridTitle = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #18181B;

`
const Grid = styled.div`
    display : grid;
    grid-template-columns: repeat(3,1fr);
    gap:8px;
`
const GridMain = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 1rem;
`
const GridItem = styled(Link)`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px;
background: #FFFFFF;
border: 1.5px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 16px;
height: 300px;
.gatsby-image-wrapper {
    height: 230px;
    border-radius: 16px;
}
`
const ImgGrid = styled.div`
    width: 100%;
    border-radius: 15px;
    .img {
        height: 196px;
    }
    .imgSmall {
        height: 136px !important;
    }
`
const TitleGrid = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;


`
const AllArticles = styled.button`
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #4F4F4F;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;

background: #FCFCFC;
/* Gray 5 */

border: 1px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 8px;
`
const GridElement = styled(Link)`
    padding: 12px;
    background: #FFFFFF;
/* Shukran Rechta DS / Other elements / Color 5 */

border: 1.5px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 16px;
@media screen and (max-width: 800px) {
    height: 300px;
}
.gatsby-image-wrapper {
    border-radius: 16px;
}
`
const ReadMore = styled.button`
display: flex;
flex-direction: row;

justify-content: center;
align-items: center;
padding: 16px 32px;
background: #DAFAD4;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #2A5E1A;
position: absolute;

`
const AllWrap = styled.div`
    display:flex;
    justify-content: center;
    margin-top: 2rem;
`
const ReadWrap = styled.div`
   display:flex;
   width: 100%;
    justify-content: center;
    margin-top: 1rem;  
    display: none;
    position: relative;
    animation: Anime 1.3s; 
@keyframes Anime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
`

interface BlogProps {
    blogData: any,
    recipes:any,
    lang:string
}

const Blog: React.FC<BlogProps> = ({blogData,lang,recipes}) => {
    React.useEffect(() =>
    {
        const elemnt = document.querySelectorAll('.element');
        for(let i = 0; i< elemnt.length ; i++) {
            elemnt[i].addEventListener("mouseover", () =>
            {
              elemnt[i].classList.add('active')
              elemnt[i].children[0].classList.add('imgSmall')
              elemnt[i].children[2].classList.add('show')
                
            })
            elemnt[i].addEventListener("mouseleave", () =>
            {
              elemnt[i].classList.remove('active')
              elemnt[i].children[0].classList.remove('imgSmall')
              elemnt[i].children[2].classList.remove('show')
             
            })
        }
        
    })
        return (
          <Wrap>
          <MainBlog blogData={blogData} lang={lang} />
          {recipes.nodes.length>0 && <RecipesBlog data={recipes} lang={lang}/>}
          <LatestBlog blogData={blogData} lang={lang}/>
          <FAQ lang={lang}/>
          </Wrap>
        );
}
export default Blog