import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrap = styled.div`
    /* margin: 0 346px; */
`
const BlogTitle = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 57px;
text-align: center;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;

`
const BlogP = styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #424242;
mix-blend-mode: normal;
margin-top: -1rem;
`
const GridTitle = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #18181B;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2 ; /* number of lines to show */
-webkit-box-orient: vertical;

`
const Grid = styled.div`
    display : grid;
    grid-template-columns: repeat(3,1fr);
    gap:8px;
    @media screen and (max-width: 850px) {
    grid-template-columns: repeat(1,1fr);
}
`
const GridMain = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 1rem;
    @media screen and (max-width: 850px) {
    grid-column-start: 1;
    grid-column-end: 2;
}
`
const GridItem = styled(Link)`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px;
background: #FFFFFF;
border: 1.5px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 16px;
height: 330px;
@media screen and (max-width: 500px) {
    height: auto;
}
.gatsby-image-wrapper {
    height: 230px;
    border-radius: 16px;
}

`
const ImgGrid = styled.div`
    width: 100%;
    border-radius: 15px;
    .img {
        height: 196px;
    }
    .imgSmall {
        height: 136px !important;
    }
`
const TitleGrid = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 4 ; /* number of lines to show */
-webkit-box-orient: vertical;
/* Shukran Rechta DS/Text/Color 2 */

color: #000000;


`
const AllArticles = styled.button`
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #4F4F4F;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;

background: #FCFCFC;
/* Gray 5 */

border: 1px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 8px;
transition: .5s all ease;
&:hover {
    border: 1px solid #4F4F4F;
    transition: .5s all ease;
}
`
const GridElement = styled(Link)`
    height:371px;
    padding: 12px;
    background: #FFFFFF;
/* Shukran Rechta DS / Other elements / Color 5 */

border: 1.5px solid #E0E0E0;
box-shadow: 0px 4px 4px  rgba(0, 0, 0, 0.12);
border-radius: 16px;
@media screen and (max-width: 800px) {
    height: 300px;
}
@media screen and (max-width: 500px) {
    height: auto;
}
.gatsby-image-wrapper {
    border-radius: 16px;
}
@media screen and (max-width: 850px) {
    grid-column-start: 1;
    grid-column-end: 2;
}
`
const ReadMore = styled.button`
display: flex;
flex-direction: row;

justify-content: center;
align-items: center;
padding: 16px 32px;
background: #DAFAD4;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #2A5E1A;
position: absolute;
@media screen and (max-width: 500px) {
    display: none;
}
`
const AllWrap = styled.div`
    display:flex;
    justify-content: center;
    margin-top: 2rem;
`
const ReadWrap = styled.div`
   display:flex;
   width: 100%;
    justify-content: center;
    margin-top: 1rem;  
    display: none;
    position: relative;
    animation: Anime 1.3s; 
@keyframes Anime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}    text-overflow:ellipsis;
@media screen and (max-width: 500px) {
    display: none;
}
`
interface BlogProps {
    blogData: any
    lang: string
}

const MainBlog: React.FC<BlogProps> = ({blogData,lang}) => {
    const[end,setEnd]=useState(4)
    React.useEffect(() =>
    {
        const elemnt = document.querySelectorAll('.element');
        for(let i = 0; i< elemnt.length ; i++) {
            elemnt[i].addEventListener("mouseover", () =>
            {
              elemnt[i].classList.add('active')
              elemnt[i].children[0].classList.add('imgSmall')
              elemnt[i].children[2].classList.add('show')
                
            })
            elemnt[i].addEventListener("mouseleave", () =>
            {
              elemnt[i].classList.remove('active')
              elemnt[i].children[0].classList.remove('imgSmall')
              elemnt[i].children[2].classList.remove('show')
             
            })
        }
        
    })
        return (
            <Wrap>
            <BlogTitle>
                Blog
            </BlogTitle>
          
            <Grid>
                <GridMain>
                  
                        <GridItem  to={`/${lang}/article/${blogData.nodes[0].uid}`}  className='element'>
                            <ImgGrid>
                        <GatsbyImage image={blogData.nodes[0].data.main_image.gatsbyImageData} alt='Img' />
                            </ImgGrid>
                        <GridTitle>
                            {blogData.nodes[0].data.title.text}
                        </GridTitle>
                        <ReadWrap>
                <ReadMore>
                    Read More
                </ReadMore>
                </ReadWrap>
                        </GridItem>
                   
                </GridMain>
              {blogData.nodes.slice(1,end).map((blog:any) => 
             ( <GridElement key={blog.uid} to={`/${lang}/article/${blog.uid}`} className='element' >
              <ImgGrid>
              <GatsbyImage className='img' image={blog.data.main_image.gatsbyImageData} alt='Image' />
          </ImgGrid>
              <TitleGrid>
            {blog.data.title.text}
          </TitleGrid>
          <ReadWrap>
          {lang === 'fr-fr' ?
                <ReadMore>
               Lire la suite
                </ReadMore>
                :
                <ReadMore>
                Read more
                </ReadMore>
                }
          </ReadWrap>
          
              </GridElement>)
              )}
            </Grid>
            {blogData.nodes.length>end && <AllWrap>
                {lang === 'fr-fr' ?
                <AllArticles onClick={()=>{setEnd(blogData.nodes.length)}}>
                    Voir tous les articles
                </AllArticles>
                :
                <AllArticles  onClick={()=>{setEnd(blogData.nodes.length)}}>
                    See all articles
                </AllArticles>
                }
                
                </AllWrap>}
        </Wrap>
        );
}
export default MainBlog