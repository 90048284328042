import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { slugify } from '../../utils/slugify'
import ShowMore from '../ShowMore'

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 8px;
    @media screen and (max-width: 850px) {
    grid-template-columns: repeat(1,1fr);
}
`
const Wrap = styled.div`
    margin: 120px 0;
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 57px;
text-align: center;
color: #000000;
`
const P = styled.p`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-align: center;
margin-top: -1rem;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;

mix-blend-mode: normal;
`
const GridElement = styled(Link)`
    height:350px;
    padding: 12px;
    background: #FFFFFF;
    /* Shukran Rechta DS / Other elements / Color 5 */
    border: 1.5px solid #E0E0E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    @media screen and (max-width: 1100px) {
        height: 390px;
    }
    @media screen and (max-width: 800px) {
        height: 300px;
    }
    @media screen and (max-width: 500px) {
        height: auto;
    }
    .gatsby-image-wrapper {
        border-radius: 16px;
    }
    @media screen and (max-width: 850px) {
        grid-column-start: 1;
        grid-column-end: 2;
    }
`
const ReadMore = styled.button`
display: flex;
flex-direction: row;

justify-content: center;
align-items: center;
padding: 16px 32px;
background: #DAFAD4;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #2A5E1A;
position: absolute;
@media screen and (max-width: 500px) {
    display: none;
}
`
const AllWrap = styled.div`
    display:flex;
    justify-content: center;
    margin-top: 2rem;
`
const AllArticles = styled.button`
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #4F4F4F;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;

background: #FCFCFC;
/* Gray 5 */

border: 1px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 8px;
transition: .5s all ease;
&:hover {
    border: 1px solid #4F4F4F;
    transition: .5s all ease;
}
`
const ReadWrap = styled.div`
   display:flex;
   width: 100%;
    justify-content: center;
    margin-top: 1rem;  
    display: none;
    position: relative;
    animation: Anime 1.3s; 
@keyframes Anime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@media screen and (max-width: 500px) {
    display: none;
}
`
const ImgGrid = styled.div`
    width: 100%;
    border-radius: 15px;
    .img {
        height: 196px;
    }
    .imgSmall {
        height: 136px !important;
    }
`
const TitleGrid = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 4 ; /* number of lines to show */
-webkit-box-orient: vertical;
/* Shukran Rechta DS/Text/Color 2 */

color: #000000;


`
interface RecipesBlogProps {
    data : any
    lang: string
}

const RecipesBlog: React.FC<RecipesBlogProps> = ({data, lang}) => {
    const allColl = data.nodes
    const [list, setList] = React.useState([...allColl.slice(0, 12)])
    const [loadMore, setLoadMore] = React.useState(false)
    const [hasMore, setHasMore] = React.useState(allColl.length > 12)
    const handleLoadMore = () => {
        setLoadMore(true)
      }
      React.useEffect(() => {
        if (loadMore && hasMore) {
          const currentLength = list.length
          const isMore = currentLength < allColl.length
          const nextResults = isMore
            ? allColl.slice(currentLength, currentLength + 12)
            : []
          setList([...list, ...nextResults])
          setLoadMore(false)
        }
      }, [loadMore, hasMore]) 
      React.useEffect(() => {
        const isMore = list.length < allColl.length
        setHasMore(isMore)
      }, [list])

    React.useEffect(() =>
    {
        const elemnt = document.querySelectorAll('.element');
        for(let i = 0; i< elemnt.length ; i++) {
            elemnt[i].addEventListener("mouseover", () =>
            {
              elemnt[i].classList.add('active')
              elemnt[i].children[0].classList.add('imgSmall')
              elemnt[i].children[2].classList.add('show')
                
            })
            elemnt[i].addEventListener("mouseleave", () =>
            {
              elemnt[i].classList.remove('active')
              elemnt[i].children[0].classList.remove('imgSmall')
              elemnt[i].children[2].classList.remove('show')
             
            })
        }
        
    })
        return (
            <Wrap>
<Title>
{lang === 'fr-fr' ? 'Nos recettes' : 'Our recipes'}
</Title>
<Grid>
    {list.map((blog:any) =>
   
    (
        
<GridElement key={blog.uid}  to={`/${lang}/recipe/${blog.uid}`} className='element' >
                    <ImgGrid>
                    <GatsbyImage className='img' image={blog.data.image.gatsbyImageData} alt='Image' />
                </ImgGrid>
                    <TitleGrid>
                    {blog.data.title.text}
                </TitleGrid>
                <ReadWrap>
                {lang === 'fr-fr' ?
                <ReadMore>
               Lire la suite
                </ReadMore>
                :
                <ReadMore>
                Read more
                </ReadMore>
                }
                </ReadWrap>
                
                    </GridElement>
    )
    )}

    </Grid>
    <AllWrap>
    {hasMore && (
        <AllArticles onClick={handleLoadMore}>{lang=== 'fr-fr' ? 'Montre plus': "Show more"}</AllArticles>
        ) }
    </AllWrap>

            </Wrap>
        );
}
export default RecipesBlog